import { useAppDispatch, useMixpanel, useTranslation } from 'hooks';
import { resetProcessingIndicators, selectClientId, selectOngoingCallBotId, selectPastDataSummary, updateSavedCopilotConfigurationFormValues } from 'store/chat-assistant';
import { useSelector } from 'react-redux';
import { Fragment, useEffect, useState } from 'react';
import { router } from 'routing/router';
import { ROUTES } from 'routing/constants';
import { Button } from 'components/ui/button';
import { GeneralLayout } from 'components/layout/GeneralLayout';
import { DynamicChecklist } from 'components/common/app/copilot/meeting/DynamicChecklist';
import { Fieldset } from 'components/ui/fieldset';
import { AiConversationType, AskAIConversation } from 'components/common/app/_general/AskAIConversation';
import { SpeakerBlock } from 'components/common/app/_general/SpeakerBlock';
import { Phrase } from 'components/common/app/_general/Phrase';
import { LiveTranscript } from 'components/common/app/copilot/meeting/LiveTranscript';
import { MeetingEndedDialog } from 'components/common/app/copilot/meeting/MeetingEndedDialog';
import { HeadsUpDisplay } from 'components/common/app/copilot/meeting/HeadsUpDisplay';
import { MIXPANEL } from 'const';
import { MarkdownRenderer } from 'components/common/_atoms/MarkdownRenderer';

export const AssistedCopilotMeetingPage = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { trackEvent } = useMixpanel();

  const pastDataSummary = useSelector(selectPastDataSummary);
  const clientId = useSelector(selectClientId);

  const ongoingCallBotId = useSelector(selectOngoingCallBotId);
  const [meetingBotId, setMeetingBotId] = useState('');
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    dispatch(updateSavedCopilotConfigurationFormValues(null))
    return () => {
      dispatch(resetProcessingIndicators())
    }
  }, [dispatch])

  useEffect(() => {
    if (ongoingCallBotId) {
      setMeetingBotId(ongoingCallBotId)
    }
  }, [ongoingCallBotId])

  useEffect(() => {
    if (!ongoingCallBotId && !meetingBotId) {
      router.navigate(ROUTES.APP.COPILOT.CONFIGURE);
    }

    setModalOpen(!ongoingCallBotId && !!meetingBotId)
  }, [ongoingCallBotId, meetingBotId])

  return (
    <GeneralLayout
      hideMenu={true}
      isOneScreenOnly={true}
    >
      <div className="h-32">
        <Fieldset
          legend={t('page.AssistedCopilotMeetingPage.headsUp')}
          wrapperClassName='h-full'
          className='justify-center'
          divVersion={true}
        >
          <HeadsUpDisplay botId={ongoingCallBotId} />
        </Fieldset>
      </div>

      <div className="flex flex-1 overflow-auto gap-6">
        <Fieldset legend={t('page.AssistedCopilotMeetingPage.checklist')}>
          <DynamicChecklist botId={ongoingCallBotId} />
        </Fieldset>

        <div className='flex flex-1 overflow-auto gap-6 flex-col'>
          <Fieldset legend={t('page.AssistedCopilotMeetingPage.pastClientData')}>
            <AskAIConversation
              botId={ongoingCallBotId}
              clientId={clientId}
              placeholder={t('page.AssistedCopilotMeetingPage.pastClientDataPlaceholder')}
              type={AiConversationType.CLIENT_DATA}
              preContent={pastDataSummary ? (
                <SpeakerBlock speaker={t('general.copilot')}>
                  <Phrase>
                    <MarkdownRenderer
                      markdown={pastDataSummary}
                    />
                  </Phrase>
                </SpeakerBlock>
              ) : null}
            />
          </Fieldset>

          <Fieldset legend={t('page.AssistedCopilotMeetingPage.meetingTranscript')}>
            <LiveTranscript botId={ongoingCallBotId} />

            <AskAIConversation
              botId={ongoingCallBotId}
              clientId={clientId}
              placeholder={t('page.AssistedCopilotMeetingPage.meetingTranscriptPlaceholder')}
              type={AiConversationType.TRANSCRIPT}
              conversationHiddenUntilContent={true}
            />
          </Fieldset>
        </div>
      </div>

      <div className="h-24 w-full lg:w-1/2 self-center">
        <Fieldset
          className='justify-center items-center'
        >
          <Fragment>
            <Button
              className='w-full max-w-2xl'
              onClick={() => {
                trackEvent({ action: MIXPANEL.ACTION.COPILOT.GO_TO_SUMMARY })
                router.navigate({
                  pathname: ROUTES.APP.COPILOT.SUMMARY,
                  search: `?meetingId=${ongoingCallBotId}`
                })
              }}
            >
              {t('page.AssistedCopilotMeetingPage.goToSummary')}
            </Button>

            <MeetingEndedDialog
              modalOpen={modalOpen}
              meetingBotId={meetingBotId}
            />
          </Fragment>
        </Fieldset>
      </div>
    </GeneralLayout>
  );
};

