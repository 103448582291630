import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { getProfileDetails, selectProfileDetails } from 'store/profile';
import {
  IConfigureCopilotMeetingForm, selectIsCreateBotProcessing,
  updateSavedCopilotConfigurationFormValues,
  selectSavedCopilotConfigurationFormValues,
  createBot,
  IUploadInPersonRecordingData,
  setInPersonRecordingData,
  CopilotMeetingType,
  selectSavedCopilotConfigurationMeetingType,
  updateSavedCopilotConfigurationMeetingType,
  setIsCreateBotProcessing
} from 'store/chat-assistant';
import { IClientData, getClientsList, selectClientsList, selectIsGetClientsListProcessing } from 'store/clientManagement';
import { useAppDispatch, useMixpanel, useTranslation } from 'hooks';
import { useForm } from 'react-hook-form';
import Editor from 'react-simple-code-editor';
import { z } from "zod"
import { zodResolver } from "@hookform/resolvers/zod"
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from 'components/ui/form';
import { Input } from 'components/ui/input';
import { Button } from 'components/ui/button';
import { Textarea } from 'components/ui/textarea';
import { Select, SelectContent, SelectGroup, SelectItem, SelectLabel, SelectTrigger, SelectValue } from 'components/ui/select';
import { CardFooter } from 'components/ui/card';
import { Loading } from 'components/layout-atoms/Loading';
import { LOCAL_STORAGE, MIXPANEL } from 'const';
import { AddClientModal } from 'components/common/app/_general/AddClientModal';
import { AddTemplateModal } from 'components/common/app/_general/AddTemplateModal';
import { IMeetingTemplate, getTemplates, selectIsGettingTemplates, selectMeetingTemplates } from 'store/templates-management';
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from 'components/ui/accordion';
import { InfoIcon } from 'lucide-react';
import { TemplateReadOnlyDisplay } from 'components/common/_atoms/TemplateReadOnlyDisplay';
import { router } from 'routing/router';
import { ROUTES } from 'routing/constants';
import { enhanceWithLineNumbers } from 'lib/utils';
import { i18n } from 'i18n';
import { Tabs, TabsList, TabsTrigger } from 'components/ui/tabs';
import { trim } from 'lodash';
import { selectActiveCalendarEvent } from 'store/calendar';

const onlineSchema = z.object({
  client_id: z.string().min(1, i18n.t('errors.required') || ''),
  template_id: z.string().optional(),
  data_points: z.string().optional(),
  questions: z.string().optional(),
  extra_questions: z.string().optional(),
  bot_name: z.string().min(1, i18n.t('errors.required') || ''),
  meeting_link: z.string().min(1, i18n.t('errors.required') || ''),
  advisor_name: z.string().min(1, i18n.t('errors.required') || ''),
  past_data: z.string().optional(),
});

const defaultFormValues: IConfigureCopilotMeetingForm = {
  bot_name: localStorage.getItem(LOCAL_STORAGE.NOTETAKER_NAME) || 'Notetaker',
  meeting_link: '',
  advisor_name: '',
  past_data: '',
  client_id: '',
  template_id: '',
  extra_questions: '',
}

const inPersonSchema = z.object({
  client_id: z.string().min(1, i18n.t('errors.required') || ''),
  template_id: z.string().optional(),
  extra_questions: z.string().optional(),
  bot_name: z.string().optional(),
  meeting_link: z.string().optional(),
  advisor_name: z.string().optional(),
  past_data: z.string().optional(),
});


export const ConfigureCopilotForm = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { trackEvent } = useMixpanel();

  const savedCopilotMeetingType = useSelector(selectSavedCopilotConfigurationMeetingType);

  const [meetingType, setMeetingType] = useState<CopilotMeetingType>(savedCopilotMeetingType || CopilotMeetingType.ONLINE);
  const [isAddClientFormVisible, setIsAddClientFormVisible] = useState(false);
  const [isAddTemplateModalVisible, setIsAddTemplateModalVisible] = useState(false);
  const [activeTemplate, setActiveTemplate] = useState<IMeetingTemplate>()

  const isCreateBotProcessing = useSelector(selectIsCreateBotProcessing);

  const profileDetails = useSelector(selectProfileDetails);

  const meetingTemplates = useSelector(selectMeetingTemplates);
  const isGettingTemplates = useSelector(selectIsGettingTemplates);
  const isGetClientsListProcessing = useSelector(selectIsGetClientsListProcessing);
  const clientsList = useSelector(selectClientsList);

  const savedCopilotConfiguration = useSelector(selectSavedCopilotConfigurationFormValues);
  const activeCalendarEvent = useSelector(selectActiveCalendarEvent);

  const { schema, defaultValues } = useMemo(() => {
    switch (meetingType) {
      case CopilotMeetingType.ONLINE:
        return { schema: onlineSchema, defaultValues: defaultFormValues };
      case CopilotMeetingType.IN_PERSON:
        return { schema: inPersonSchema, defaultValues: defaultFormValues };
      default:
        throw new Error('Invalid form type');
    }
  }, [meetingType]);


  const form = useForm({
    resolver: zodResolver(schema),
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues: savedCopilotConfiguration || defaultValues
  });

  useEffect(() => {
    dispatch(setIsCreateBotProcessing(false))
    dispatch(getProfileDetails())
    dispatch(getClientsList())
    dispatch(getTemplates());

    return () => {
      dispatch(updateSavedCopilotConfigurationFormValues(form.getValues()))
    }
  }, [dispatch])

  useEffect(() => {
    if (meetingType) {
      dispatch(updateSavedCopilotConfigurationMeetingType(meetingType))
    }
  }, [meetingType, dispatch])

  useEffect(() => {
    if (meetingType === CopilotMeetingType.ONLINE) {
      const formAdvisorValue = form.getValues('advisor_name');

      if (!formAdvisorValue && profileDetails && profileDetails?.firstName) {
        form.setValue('advisor_name', `${profileDetails.firstName} ${profileDetails.lastName}`)
      }
    }
  }, [profileDetails, form, meetingType]);

  const watchTemplateChange = form.watch("template_id");
  const watchNotetakerNameChange = form.watch("bot_name");

  useEffect(() => {
    const selectedMeetingTemplate = [...meetingTemplates?.myTemplates || [], ...meetingTemplates?.orgTemplates || []]?.find(template => template.id === watchTemplateChange)

    setActiveTemplate(selectedMeetingTemplate);
  }, [watchTemplateChange, form, savedCopilotConfiguration, meetingTemplates]);

  useEffect(() => {
    localStorage.setItem(LOCAL_STORAGE.NOTETAKER_NAME, watchNotetakerNameChange);
  }, [watchNotetakerNameChange]);

  useEffect(() => {
    if (!activeCalendarEvent) {
      return;
    }

    if (activeCalendarEvent?.meetingUrl) {
      form.setValue('meeting_link', activeCalendarEvent?.meetingUrl)
    } else {
      form.setValue('meeting_link', '')
    }

    if (activeCalendarEvent?.participants) {
      activeCalendarEvent?.participants?.find(participant => {
        const participantEmail = trim(participant || '')?.toLowerCase()

        const clientMatched = clientsList?.find(client => {
          const clientEmail = trim(client?.email || '')?.toLowerCase()

          return clientEmail === participantEmail
        })

        if (!!clientMatched) {
          form.setValue('client_id', clientMatched?.id)
        } else {
          form.setValue('client_id', '')
        }

        return !!clientMatched
      })
    } else {
      form.setValue('client_id', '')
    }
  }, [activeCalendarEvent, form, clientsList]);

  const handleAddNewClient = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    setIsAddClientFormVisible(true)
  }

  const handleAddNewTemplate = () => {
    router.navigate(ROUTES.APP.SETTINGS.MY_TEMPLATES)
  }

  const handleOnCreateClientCompleted = (newClientData: IClientData) => {
    form.setValue('client_id', newClientData.id);
  }

  const handleOnCreateTemplateCompleted = (newTemplate: IMeetingTemplate) => {
    form.setValue('template_id', newTemplate.id);
  }


  const handleFormSubmit = (values: IConfigureCopilotMeetingForm) => {
    const extraQuestions = (values?.extra_questions || '')?.split("\n").filter(item => item?.length)?.slice(0, 20) || [];

    if (meetingType === CopilotMeetingType.IN_PERSON) {
      trackEvent({ action: MIXPANEL.ACTION.COPILOT.LAUNCH_COPILOT_IN_PERSON })

      const uploadDataPayload: IUploadInPersonRecordingData = {
        advisor_name: `${profileDetails?.firstName} ${profileDetails?.lastName}`,
        client_id: values?.client_id,
        template_id: values?.template_id,
        questions: extraQuestions
      }

      dispatch(setInPersonRecordingData(uploadDataPayload))

      router.navigate(ROUTES.APP.COPILOT.IN_PERSON);
    }

    if (meetingType === CopilotMeetingType.ONLINE) {
      trackEvent({ action: MIXPANEL.ACTION.COPILOT.LAUNCH_COPILOT_ONLINE })

      dispatch(createBot({
        ...values,
        questions: extraQuestions,
        past_data: values?.past_data || ''
      }));
    }
  }

  return (
    <>
      <Tabs value={meetingType} className="flex flex-col mb-4" onValueChange={(value) => setMeetingType(value as CopilotMeetingType)}>
        <TabsList className='flex w-full'>
          <TabsTrigger className='grow' value={CopilotMeetingType.ONLINE}>{t('page.ConfigureCopilotPage.tab.online')}</TabsTrigger>
          <TabsTrigger className='grow' value={CopilotMeetingType.IN_PERSON}>{t('page.ConfigureCopilotPage.tab.inPerson')}</TabsTrigger>
        </TabsList>
      </Tabs>

      <Form {...form}>
        <form className="flex flex-col gap-6 grow" onSubmit={form.handleSubmit(handleFormSubmit)}>
          <div className="flex flex-col grow gap-6 px-0">
            {meetingType && [CopilotMeetingType.ONLINE].includes(meetingType) && (
              <>
                <FormField
                  control={form.control}
                  name="bot_name"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>
                        {t('component.ConfigureWebCopilotForm.copilotName')}
                      </FormLabel>
                      <FormLabel variant="description">
                        {t('component.ConfigureWebCopilotForm.copilotNameDesc')}
                      </FormLabel>

                      <FormControl>
                        <Input {...field} data-1p-ignore />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="meeting_link"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>
                        {t('component.ConfigureWebCopilotForm.meetingLink')}
                      </FormLabel>
                      <FormLabel variant="description">
                        {t('component.ConfigureWebCopilotForm.meetingLinkDesc')}
                      </FormLabel>

                      <FormControl>
                        <Input {...field} data-1p-ignore />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="advisor_name"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>
                        {t('component.ConfigureWebCopilotForm.advisorName')}
                      </FormLabel>

                      <FormControl>
                        <Input {...field} data-1p-ignore />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </>
            )}

            <FormField
              control={form.control}
              name="client_id"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>
                    {t('component.ConfigureWebCopilotForm.clientName')}
                  </FormLabel>
                  <FormLabel variant="description">
                    {t('component.ConfigureWebCopilotForm.clientNameDesc')}
                  </FormLabel>

                  <div className='flex gap-3 items-center'>
                    {isGetClientsListProcessing && <Loading variant="item" className='basis-2/3 p-[9px] rounded-md' />}
                    {!isGetClientsListProcessing && clientsList?.length > 0 && (
                      <div className='basis-2/3'>
                        <Select onValueChange={field.onChange} defaultValue={field.value} value={field.value}>
                          <FormControl>
                            <SelectTrigger>
                              <SelectValue placeholder={t('component.ConfigureWebCopilotForm.clientNameSelect')} />
                            </SelectTrigger>
                          </FormControl>
                          <SelectContent>
                            {clientsList?.map(client => (
                              <SelectItem key={client.id} value={client.id}>{`${client.firstName} ${client.lastName}`}</SelectItem>
                            ))}
                          </SelectContent>
                        </Select>
                      </div>
                    )}

                    {(isGetClientsListProcessing || clientsList?.length > 0) && <span className='text-sm'>OR</span>}

                    <Button type='button' className='basis-1/3 min-w-48' onClick={handleAddNewClient}>
                      {t('component.ConfigureWebCopilotForm.addClient')}
                    </Button>
                  </div>

                  <FormMessage />
                </FormItem>
              )}
            />

            {meetingType && [CopilotMeetingType.ONLINE].includes(meetingType) && (
              <FormField
                control={form.control}
                name="past_data"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>
                      {t('component.ConfigureWebCopilotForm.pastData')}
                    </FormLabel>
                    <FormLabel variant="description">
                      {t('component.ConfigureWebCopilotForm.pastDataDesc')}
                    </FormLabel>
                    <FormLabel variant="description">
                      {t('component.ConfigureWebCopilotForm.pastDataDescExtra')}
                    </FormLabel>

                    <FormControl>
                      <Textarea rows={3} {...field} data-1p-ignore />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            )}


            <FormField
              control={form.control}
              name="template_id"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>
                    {t('component.ConfigureWebCopilotForm.template')}
                  </FormLabel>
                  <FormLabel variant="description">
                    {t('component.ConfigureWebCopilotForm.templateDesc')}
                  </FormLabel>

                  <div className='flex gap-3 items-center'>
                    {isGettingTemplates && <Loading variant="item" className='basis-2/3 p-[9px] rounded-md' />}
                    {!isGettingTemplates && (meetingTemplates?.orgTemplates?.length > 0 || meetingTemplates?.myTemplates?.length > 0) && (
                      <div className='basis-2/3'>
                        <Select onValueChange={field.onChange} defaultValue={field.value} value={field.value}>
                          <FormControl>
                            <SelectTrigger>
                              <SelectValue placeholder={t('component.ConfigureWebCopilotForm.templateSelect')} />
                            </SelectTrigger>
                          </FormControl>
                          <SelectContent>
                            {meetingTemplates?.orgTemplates?.length > 0 && (
                              <SelectGroup>
                                <SelectLabel>
                                  {t('general.orgTemplates')}
                                </SelectLabel>
                                {meetingTemplates?.orgTemplates?.map(template => (
                                  <SelectItem
                                    key={template.id}
                                    value={template.id}
                                  >
                                    {template.name}
                                  </SelectItem>
                                ))}
                              </SelectGroup>
                            )}
                            {meetingTemplates?.myTemplates?.length > 0 && (
                              <SelectGroup>
                                <SelectLabel>
                                  {t('general.myTemplates')}
                                </SelectLabel>
                                {meetingTemplates?.myTemplates?.map(template => (
                                  <SelectItem
                                    key={template.id}
                                    value={template.id}
                                  >
                                    {template.name}
                                  </SelectItem>
                                ))}
                              </SelectGroup>
                            )}
                          </SelectContent>
                        </Select>
                      </div>
                    )}

                    {(isGettingTemplates || (meetingTemplates?.orgTemplates?.length > 0 || meetingTemplates?.myTemplates?.length > 0)) && <span className='text-sm'>OR</span>}

                    <Button type='button' className='basis-1/3 min-w-48' onClick={handleAddNewTemplate}>
                      {t('component.ConfigureWebCopilotForm.addTemplate')}
                    </Button>
                  </div>
                  <FormMessage />
                </FormItem>
              )}
            />

            {activeTemplate && (
              <Accordion type="single" collapsible className="w-full">
                <AccordionItem value="item-1" className='border-b-0'>
                  <AccordionTrigger className='hover:no-underline py-0'>
                    <div className='flex justify-center items-center gap-3'>
                      <InfoIcon className='size-4' />
                      {t('component.ConfigureWebCopilotForm.clickToExpand')}
                    </div>
                  </AccordionTrigger>
                  <AccordionContent>
                    <TemplateReadOnlyDisplay template={activeTemplate} className='flex gap-3 mt-2' cardClassName='flex-1 bg-muted' />
                  </AccordionContent>
                </AccordionItem>
              </Accordion>
            )}

            <FormField
              control={form.control}
              name="extra_questions"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>
                    {t('component.ConfigureWebCopilotForm.extraQuestions')}
                  </FormLabel>
                  <FormLabel variant="description">
                    {t('component.ConfigureWebCopilotForm.extraQuestionsDesc')}
                  </FormLabel>

                  <FormControl>
                    <div className='h-[180px] overflow-y-auto border focus-within:border-primary/50 rounded-md'>
                      <Editor
                        onValueChange={() => { }}
                        highlight={text => enhanceWithLineNumbers(text)}
                        padding={12}
                        className="text-sm border border-input min-h-[178px] rounded-md"
                        textareaClassName='focus:outline-none'
                        {...field}
                      />
                    </div>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>

          <CardFooter className="flex-col gap-4 items-center pb-0">
            {meetingType && [CopilotMeetingType.ONLINE].includes(meetingType) ? (
              <>
                <Button
                  loading={isCreateBotProcessing}
                  type="submit"
                  className='w-full'
                >
                  {t('component.ConfigureWebCopilotForm.launch')}
                </Button>

                <FormLabel variant="description" className='text-center'>
                  {t('component.ConfigureWebCopilotForm.launchDesc')}
                </FormLabel>
              </>
            ) : (
              <>
                <Button
                  type="submit"
                  className='w-full'
                >
                  {t('component.ConfigureInPersonCopilotForm.launch')}
                </Button>
              </>
            )}
          </CardFooter>
        </form>
      </Form>

      <AddClientModal
        isVisible={isAddClientFormVisible}
        setIsVisible={setIsAddClientFormVisible}
        onCreateCompleted={handleOnCreateClientCompleted}
      />

      <AddTemplateModal
        isVisible={isAddTemplateModalVisible}
        setIsVisible={setIsAddTemplateModalVisible}
        onCreateCompleted={handleOnCreateTemplateCompleted}
      />
    </>
  );
};
